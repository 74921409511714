<template>
    <div>
        <h2>Sales Report</h2>
        <div class="row">
            <div class="col-3">
                <label for="user">User:</label>
                <Dropdown v-model="selectedUser" :options="users" optionLabel="fullName" placeholder="Select a User" />
            </div>
            <div class="col-3">
                <label for="start">Start Date:</label>
                <Calendar v-model="startDate" dateFormat="yy-mm-dd" showIcon />
            </div>
            <div class="col-3">
                <label for="end">End Date:</label>
                <Calendar v-model="endDate" dateFormat="yy-mm-dd" showIcon />
            </div>
            <div class="col-3">
                <label for="product">Product:</label>
                <Dropdown v-model="selectedProduct" :options="products" optionLabel="pname" placeholder="Select a Product" />
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-3">
                <Button label="Get Report" icon="pi pi-check" @click="fetchReport" />
            </div>
            <div class="col-3">
                <Button label="Download CSV" icon="pi pi-download" @click="downloadCSV" />
            </div>
        </div>
        <br>
        <br>
        <br>

        <DataTable :value="report" class="p-datatable-gridlines p-datatable-sm" showGridlines>
            <template #header>
                <div class="table-header">Sales Report</div>
            </template>
            <template #empty>No data</template>
            <Column field="first_name" header="First Name">
                <template #body="slotProps">
                    {{ slotProps.data.user_id }} - {{ slotProps.data.first_name }}
                </template>
            </Column>
            <Column field="product_id" header="Product ID"></Column>
            <Column field="product_name" header="Product Name"></Column>
            <Column field="quantity_sold" header="Quantity Sold"></Column>
            <Column field="total_sales" header="Total Sales">
                <template #body="slotProps">
                    {{ slotProps.data.total_sales || 0 }} €
                </template>
            </Column>
            <Column field="total_appointment_sales" header="Total Appointment Sales">
                <template #body="slotProps">
                    {{ slotProps.data.total_appointment_sales || 0 }} €
                </template>
            </Column>
             <Column field="total_appointment_sales" header="Total">
                <template #body="slotProps">
                    {{ Number(slotProps.data.total_sales) + Number(slotProps.data.total_appointment_sales) }} €
                </template>
            </Column>
            <Column header="Actions">
                <template #body="slotProps">
                    <Button icon="pi pi-info" @click="openTransactionDetails(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
        <div v-if="report.length">
            <h3>Total Products Sold: {{ totalProducts }}</h3>
            <h3>Total Sales: {{ totalSales }}</h3>
            <h3>Total Appointment Sales: {{ totalAppointmentSales }}</h3>
        </div>

        <Dialog :visible.sync="isModalVisible" :header="modalTitle" :modal="true" :style="{ width: '50vw' }">
            <h3>Cart Transactions</h3>
            <DataTable :value="modalData.cartTransactions" class="p-datatable-gridlines p-datatable-sm" showGridlines>
                <Column field="date" header="Date"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="price" header="Price">
                    <template #body="slotProps">
                        {{ slotProps.data.price }} €
                    </template>
                </Column>
            </DataTable>
            <h3>Appointment Transactions</h3>
            <DataTable :value="modalData.appointmentTransactions" class="p-datatable-gridlines p-datatable-sm" showGridlines>
                <Column field="date" header="Date"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="price" header="Price">
                    <template #body="slotProps">
                        {{ slotProps.data.price }} €
                    </template>
                </Column>
            </DataTable>
        </Dialog>
    </div>
</template>

<script>
import Column from 'primevue/column/Column';



export default {
    components: {

        
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            report: [],
            users: [],
            products: [],
            selectedUser: null,
            selectedProduct: null,
            totalSales: 0,
            totalAppointmentSales: 0,
            totalProducts: 0,
            isModalVisible: false,
            isModalVisible: false,
            modalData: { cartTransactions: [], appointmentTransactions: [] },
            modalTitle: ''
        };
    },
    methods: {
        async fetchReport() {
            try {
                const userId = this.selectedUser ? this.selectedUser.id : '';
                const productId = this.selectedProduct ? this.selectedProduct.id : '';
                const start = this.startDate ? new Date(this.startDate).toISOString() : '';
                const end = this.endDate ? new Date(this.endDate).toISOString() : '';
                const response = await this.$http.post(`carts/sales?start=${start}&end=${end}&userId=${userId}&productId=${productId}`);
                this.report = response.data;
                this.calculateTotals();
            } catch (error) {
                console.error('Error fetching report:', error);
            }
        },
        async fetchAdmins() {
            try {
                const response = await this.$http.get('admins');
                this.users = response.data.data;
            } catch (error) {
                console.error('Error fetching admins:', error);
            }
        },
        async fetchProducts() {
            try {
                const response = await this.$http.get('products');
                this.products = response.data.data;
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        },
        calculateTotals() {
            this.totalSales = this.report.reduce((sum, row) => sum + Number(row.total_sales), 0);
            this.totalAppointmentSales = this.report.reduce((sum, row) => sum + Number(row.total_appointment_sales), 0);
            this.totalProducts = this.report.reduce((sum, row) => sum + Number(row.quantity_sold), 0);
        },
        downloadCSV() {
            const csv = this.convertToCSV(this.report);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'sales_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        convertToCSV(data) {
            const header = Object.keys(data[0]).join(',');
            const rows = data.map(row => Object.values(row).join(',')).join('\n');
            return `${header}\n${rows}`;
        },
        async openTransactionDetails(row) {
            try {
                const start = this.startDate ? new Date(this.startDate).toISOString() : '';
                const end = this.endDate ? new Date(this.endDate).toISOString() : '';
                const response = await this.$http.post(`carts/transactionDetails?start=${start}&end=${end}&userId=${row.user_id}&productId=${row.product_id}`);
                this.modalData = response.data;
                this.modalTitle = `Details for ${row.product_name}`;
                this.isModalVisible = true;
            } catch (error) {
                console.error('Error fetching transaction details:', error);
            }
        }
    },
    mounted() {
        this.fetchAdmins();
        this.fetchProducts();
    },
};
</script>

<style>
/* Add any necessary styles */
</style>
